import { useState, useEffect, useMemo } from 'react';
import { HashLink } from "react-router-hash-link";
import { motion, useAnimationControls  } from "framer-motion";
import { ReactSVG } from 'react-svg';
import { Helmet } from 'react-helmet-async';

// Components
import SEO from '../components/SEO';
import Transition from '../components/Transition';
import Form from "../components/Form";
// import Reference from "../components/Reference";

// Images
import IntroductionFotoDesktop from "../assets/images/osobni-coaching/osobni-coaching-desktop.webp";
import IntroductionFotoTablet from "../assets/images/osobni-coaching/osobni-coaching-tablet.webp";

import ServicesRedukceHmotnostiDesktop from "../assets/images/redukce-hmotnosti/redukce-hmotnosti-desktop.webp";
import ServicesRedukceHmotnostiMobile from "../assets/images/redukce-hmotnosti/redukce-hmotnosti-mobile.webp";

import ServicesBudovaniSvaloveHmotyDesktop from "../assets/images/budovani-svalove-hmoty/budovani-svalove-hmoty-desktop.webp";
import ServicesBudovaniSvaloveHmotyMobile from "../assets/images/budovani-svalove-hmoty/budovani-svalove-hmoty-mobile.webp";

import ServicesTvarovaniPostavyDesktop from "../assets/images/tvarovani-postavy/tvarovani-postavy-desktop.webp";
import ServicesTvarovaniPostavyMobile from "../assets/images/tvarovani-postavy/tvarovani-postavy-mobile.webp";

import ServicesSilovyTreninkDesktop from "../assets/images/silovy-trenink/silovy-trenink-desktop.webp";
import ServicesSilovyTreninkMobile from "../assets/images/silovy-trenink/silovy-trenink-mobile.webp";

// Icons
import FireIcon from "../assets/images/icons/fire-solid.svg";
import BicepsIcon from "../assets/images/icons/biceps.svg";
import WeightIcon from "../assets/images/icons/weight-scale-solid.svg";
import DumbbellIcon from "../assets/images/icons/dumbbell-solid.svg";

const PersonalCoaching = () => {
  
  const tabHeaders = useMemo(() => [
    ["Redukce hmotnosti", FireIcon],
    ["Budování svalové hmoty", BicepsIcon],
    ["Tvarování postavy", WeightIcon],
    ["Silový trénink", DumbbellIcon]
  ], []);

  const servicesText = useMemo(() => [
    "Nedaří se Vám shodit přebytečný tuk? Nevíte, jak začít jíst tak, aby Vaše tělo začalo správně fungovat? Při správném tréninku a správně zvoleného přístupu to už nebude problém. Svěřte se do mých rukou a já Vás povedu k Vašemu štíhlejšímu „JÁ“",
    "Máte problém s nabíráním svalové hmoty? Při správně zvoleném tréninku a správnému vedení a přístupu to dokážeme změnit.",
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Duis condimentum augue id magna semper rutrum. Vivamus luctus egestas leo. Nullam rhoncus aliquam metus. Nam quis nulla. Etiam egestas wisi a erat. Nulla pulvinar eleifend sem. Nam libero tempore, cum soluta",
    "Cílem takového tréninku je zvyšovat maximální sílu, posilovat nervový systém a postupem času i budovat svalovou hmotu."
  ], []);

  const servicesFoto = useMemo(() => [
    [ServicesRedukceHmotnostiDesktop, ServicesRedukceHmotnostiMobile],
    [ServicesBudovaniSvaloveHmotyDesktop, ServicesBudovaniSvaloveHmotyMobile],
    [ServicesTvarovaniPostavyDesktop, ServicesTvarovaniPostavyMobile],
    [ServicesSilovyTreninkDesktop, ServicesSilovyTreninkMobile]
  ], []);

  const highlightWidth = [
    "24",
    "28",
    "23.7",
    "19.5"
  ];

  const highlightPosition = [
    ".65",
    "26",
    "54.7",
    "79.9"
  ];

  const imageUrlsToPreload = [
    ServicesRedukceHmotnostiDesktop,
    ServicesRedukceHmotnostiMobile,
    ServicesBudovaniSvaloveHmotyDesktop,
    ServicesBudovaniSvaloveHmotyMobile,
    ServicesTvarovaniPostavyDesktop,
    ServicesTvarovaniPostavyMobile,
    ServicesSilovyTreninkDesktop,
    ServicesSilovyTreninkMobile
  ];
  
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(activeIndex);
  const [isAnimating, setIsAnimating] = useState(false);
  const [headerText, setHeaderText] = useState(tabHeaders[activeIndex][0]);
  const [paragraphText, setParagraphText] = useState(servicesText[activeIndex]);
  const [subserviceFoto, setSubserviceFoto] = useState(servicesFoto[activeIndex]);
  const [subserviceIcon, setSubserviceIcon] = useState(tabHeaders[activeIndex][1]);

  const textControls = useAnimationControls();
  const fotoControls = useAnimationControls();
  const iconControls = useAnimationControls();

  useEffect(() => {
    const animation = async () => {
      setIsAnimating(true);
      
      // animace ikon
      await iconControls.start({
        scale: 0,
        transition: { duration: .1 },
      });
      
      iconControls.set({ scale: 0 });
      setSubserviceIcon(tabHeaders[activeIndex][1]);

      await iconControls.start({
        scale: 1,
        transition: { duration: .1 },
      });


      // animace textu
      await textControls.start(i => ({
        x: -100,
        opacity: 0,
        transition: { delay: i * 0.1, duration: .2 },
      }));

      textControls.set({ x: 100, opacity: 0 });
      setHeaderText(tabHeaders[activeIndex][0]);
      setParagraphText(servicesText[activeIndex]);

      await textControls.start(i => ({
        x: 0,
        opacity: 1,
        transition: { delay: i * 0.1, type: 'spring', stiffness: 50, velocity: 1 },
      }));


      // animace fotografií
      await fotoControls.start({
        scale: 0,
        transition: { duration: .4 },
      });
      
      setSubserviceFoto(servicesFoto[activeIndex]);
      fotoControls.set({ scale: 0 });
      
      await fotoControls.start({
        scale: 1,
        transition: { duration: .4 },
      });
      
      setPreviousIndex(activeIndex);
      setIsAnimating(false);
    };

    if (activeIndex !== previousIndex && !isAnimating) {
      animation();
    }

  }, [activeIndex, previousIndex, isAnimating, textControls, fotoControls, servicesText, tabHeaders, servicesFoto, iconControls]);


  return (
    <>
      <SEO title="Osobní coaching • Dominik Šrámek" pageURL="domscoaching.cz/osobni-coaching" />
      <Helmet>
        {imageUrlsToPreload.map((imageUrl, index) => (
          <link key={index} rel="preload" as="image" href={imageUrl} />
        ))}  
      </Helmet>
      <section className="choaching-introduction first-section">
        <h1>Osobní coaching</h1>
        <div className="choaching-introduction-content">
          <p>Máte v plánu na sobě začít pracovat a zbavit se svého neaktivního způsobu života? Nedaří se Vám plnit své cíle? Máte strach jít sami do fitka? Pojďte to změnit! Jestli jste si alespoň jednou odpověděl/a „ANO“, tak mi neváhejte napsat nebo zavolat, udělám vše pro to, abych Vám pomohl dosáhnout Vašich cílů bez radikálních diet a omezování. Naučím Vás cvičit technicky správně a efektivně.</p>
          <HashLink smooth to="/osobni-coaching#podsluzby" className="button-fill-dark">Možnosti coachingu</HashLink>
        </div>
        <div className="choaching-introduction-image">
          <picture>
            <source media="(min-width:992px)" srcSet={IntroductionFotoDesktop} width="1920" height="1280" />
            <img src={IntroductionFotoTablet} alt="Osobní trenér v Plzni" loading="lazy" width="768" height="512" />
          </picture>  
        </div>
      </section>
      <section id="podsluzby">
        <h2>Možnosti coachingu</h2>
        <div>
          <div className="subservices-container">
            <div className="subservices-menu">
              {tabHeaders.map((tab, index) => (
                <button onClick={() => setActiveIndex(index)} key={index} className={`subservices-button ${activeIndex === index ? "active" : ""}`}><ReactSVG src={tab[1]} />{tab[0]}</button>
              ))}
              <div className="highlight-subservices-button" style={{ left: `${highlightPosition[activeIndex]}%`, width: `${highlightWidth[activeIndex]}%` }}></div>
            </div>
            <div className="subservices-content">
              <div className="subservices-content-inner center-layout">
                <div className="subservices-content-tab">
                  <div className="subservice-icon-container">
                    <motion.div className="subservice-icon" custom={0} animate={iconControls} initial={{ scale: 1 }}>
                      <ReactSVG src={subserviceIcon} />
                    </motion.div>
                  </div>
                  <motion.h3 custom={0} animate={textControls} initial={{ x: 0, opacity: 1 }}>{headerText}</motion.h3>
                  <motion.p custom={1} animate={textControls} initial={{ x: 0, opacity: 1 }}>{paragraphText}</motion.p>
                  <motion.div custom={2} animate={textControls} initial={{ x: 0, opacity: 1 }}>
                    <HashLink smooth to="/osobni-coaching#kontakt" className="button-fill-dark">Chci si domluvit schůzku</HashLink>
                  </motion.div>
                </div>
                <motion.div className="subservices-image-tab" animate={fotoControls} initial={{ scale: 1 }}>
                  <picture>
                    <source media="(min-width:992px)" srcSet={subserviceFoto[0]} width="1920" height="1280" />
                    <img src={subserviceFoto[1]} alt="Osobní trenér v Plzni" loading="lazy" width="768" height="512" />
                  </picture>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="o-mne" className="services-about">
        <h2>O mně</h2>
        <p>Jmenuji se Dominik a jsem fitness trenér. Jako trenér působím v Premier fitness v Plzni. Mým cílem je plnění cílů a snů mých klientů, kteří si jdou bez ostychu za tím svým, ať už jde o redukci tělesného tuku nebo budování svalové hmoty. Po čas naší spolupráce nebudu jen Vaším trenérem, ale Vaším mentorem! Naučím Vás vážit si sám sebe! Hlavně nezapomínejte, že začátky bývají těžké, ale výsledek stojí za to!</p>
        <HashLink smooth to="/osobni-coaching#reference" className="button-fill-dark">Co říkají moji klienti</HashLink>
      </section>
      {/*<Reference />*/}
      <section style={{ display: "grid", placeItems: "center" }}>
        <Form id="kontakt" heading="test" />
      </section>
    </>
  );
};

export default Transition(PersonalCoaching);
