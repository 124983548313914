import { useEffect } from "react";
import { Link } from "react-router-dom";

// Components
import SEO from '../components/SEO';
import Transition from '../components/Transition';

const NotFound = () => {

  // Funkce pro přidání třídy "error-page" na element s ID #root
  const addErrorClass = () => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.classList.add('error-page');
    }
  };
  
  // Funkce pro odebrání třídy "error-page" z elementu s ID #root
  const removeErrorClass = () => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.classList.remove('error-page');
    }
  };
  
  // useEffect se spustí, když se komponenta načte (přistoupí na stránku)
  useEffect(() => {
    addErrorClass();
  
    // useEffect s návratovou funkcí se spustí při odstranění komponenty (odejde ze stránky)
    return () => {
      removeErrorClass();
    };
  }, []);

  return(
    <>
      <SEO title="Stránka nenalezena • Dominik Šrámek" pageURL="domscoaching.cz/" />
      <section>
        <h1>Stránka nebyla nalezena</h1>
        <p>Zkontrolujte zadanou URL nebo se zorientujte na <Link to="/"><u>úvodní stránce</u></Link>.</p>
      </section>
    </>
  );
}

export default Transition(NotFound);