import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { ReactSVG } from 'react-svg';
import { motion } from "framer-motion";

// Components
import SEO from '../components/SEO';
import Transition from '../components/Transition';
// import Reference from "../components/Reference";

// Images
import IntroductionFotoDesktop from "../assets/images/uvod-1/uvod-desktop.webp";
import IntroductionFotoTablet from "../assets/images/uvod-1/uvod-tablet.webp";

import WhyMeFotoDesktop from "../assets/images/uvod-2/uvod-desktop.webp";
import WhyMeFotoTablet from "../assets/images/uvod-2/uvod-tablet.webp";

import AboutFotoDesktop from "../assets/images/uvod-3/uvod-desktop.webp";
import AboutFotoTablet from "../assets/images/uvod-3/uvod-tablet.webp";

// Icons
import ArrowRight from "../assets/images/icons/arrow-regular-right.svg";
import PersonHarassing from "../assets/images/icons/person-harassing.svg";
import Monitor from "../assets/images/icons/monitor.svg";
 
const Home = () => {
  return(
    <>
      <SEO title="Dominik Šrámek • Fitness trenér" pageURL="https://domscoaching.cz" />
      <section id="uvod" className="no-margin">
        <div className="introduction-heading-container">
          <h1>Fitness trenér a výživový poradce</h1>
          <p className="heading-paragraph">Pomohu Vám si splnit své fitness cílé</p>
          <HashLink smooth to="/#moje-sluzby" className="button-fill-dark">Zjistěte více</HashLink>
        </div>  
        <div className="introduction-image-container">
          <picture>
            <source media="(min-width:992px)" srcSet={IntroductionFotoDesktop} width="1920" height="1280" />
            <motion.img src={IntroductionFotoTablet} alt="Plzeňský fitness trenér" width="768" height="512" initial={{ scale: 1 }} animate={{ scale: [1, 1.05, 1] }} transition={{ duration: 25, repeat: Infinity, repeatType: "mirror", ease: "linear" }} />
          </picture>
        </div>
      </section>
      <section id="moje-sluzby" className="center-layout">
        <div>
          <h2 className="services-heading">Jak Vám mohu pomoci</h2>
          <p className="services-paragraph">Chcete na sobě začít makat? Snížit váhu a zhubnout nebo nabrat svaly? Jste profesionální sportovec nebo někdo, kdo je po zranění a vyšli jste z formy? Dostanu Vás zpět na výslunní a rád Vám pomohu vybudovat Vaši vysněnou postavu.</p>
          <div className="card-container">
            <div className="card">
              <ReactSVG src={PersonHarassing} />
              <h3>Osobní coaching</h3>  
              <p>Potřebujete motivaci k pohybu? Osobní coaching je ideální volba! Díky analýze Vašeho těla a cílů získám informace pro optimální tréninkový a výživový plán.</p>
              <div className="card-links">
                <HashLink smooth to="/osobni-coaching#kontakt" className="button-fill-dark min-version">Chci osobní coaching</HashLink>
                <br/>
                <Link to="/osobni-coaching" className="button-link is-arrow"><u>Zjistit více</u> <ReactSVG src={ArrowRight} className="link-arrow" /></Link>
              </div>
            </div>
            <div className="card">
              <ReactSVG src={Monitor} />
              <h3>Online coaching</h3>  
              <p>Nemůžete do Plzně nebo preferujete cvičení doma? Vytvořím Vám online tréninkový a výživový plán.</p>
              <div className="card-links">
                <HashLink smooth to="/online-coaching#kontakt" className="button-fill-dark min-version">Chci online coaching</HashLink>
                <br/>
                <Link to="/online-coaching" className="button-link"><u>Zjistit více</u> <ReactSVG src={ArrowRight} className="link-arrow" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="proc-si-me-vybrat" className="center-layout reverse-wrap">
        <div className="why-me-image">
          <picture>
            <source media="(min-width:992px)" srcSet={WhyMeFotoDesktop} width="1920" height="2880" />
            <img src={WhyMeFotoTablet} alt="Dominik Šrámek" loading="lazy" width="768" height="1152" />
          </picture>
        </div>
        <div className="why-me-content">
          <h2>Proč si mě vybrat jako svého fitness trenéra?</h2>
          <p>Během našeho společného tréninku se nebudete nudit. Snažím se vytvořit přátelské prostředí, kde se nebudete stydět nebo během tréninku nebudete nervózní. Dokážu z Vás dostat maximum, o kterém se Vám ani nezdá. Během tréninku Vám rád zodpovím veškeré Vaše dotazy, ať už o trénincích či jídelníčkách. Do ničeho Vás nebudu tlačit, a pokud budete chtít pauzu, jsem ochoten se domluvit.</p>
          <HashLink smooth to="/#o-mne" className="button-fill-dark">O mně</HashLink>        
        </div>
      </section>
      <section id="o-mne" className="center-layout">
        <div className="about-content">
          <h2>Kdo jsem?</h2>
          <p>Jmenuji se Dominik a jsem fitness trenér. Jako trenér působím v Premier fitness v Plzni. Mým cílem je plnění cílů a snů mých klientů, kteří si jdou bez ostychu za tím svým, ať už jde o redukci tělesného tuku nebo budování svalové hmoty. Po čas naší spolupráce nebudu jen Vaším trenérem, ale Vaším mentorem! Naučím Vás vážit si sám sebe! Hlavně nezapomínejte, že začátky bývají těžké, ale výsledek stojí za to!</p>
          <Link to="/o-mne" className="button-fill-dark">Více o mně</Link>
        </div>
        <div className="about-image">
          <picture>
            <source media="(min-width:992px)" srcSet={AboutFotoDesktop} width="1920" height="2880" />
            <img src={AboutFotoTablet} alt="Dominik Šrámek" loading="lazy" width="768" height="1152" />
          </picture>
        </div>
      </section>
      {/*<Reference />*/}
      <section className="main-cta">
        <div>
          <h2>Společně překonáme nedostatek motivace a dosáhneme vašich cílů!</h2>
        </div>
        <div>
          <p>Díky mým flexibilním službám přizpůsobeným Vašim potřebám a cílům společně dosáhneme Vaší nejlepší verze sebe sama. Připojte se ke mně již dnes a začněte svou cestu ke zdraví a pohodě.</p>
          <Link smooth to="/kontakt" className="button-fill-dark">Chci coaching</Link>
        </div>
      </section>
    </>
  );
}

export default Transition(Home);