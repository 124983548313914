import { useState, useEffect, useRef, useCallback  } from "react";
import { Link, useResolvedPath, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { ReactSVG } from 'react-svg';

// Images
import logo from '../assets/images/logo/logo-mini-white.svg';

export default function Navbar(){
  const navRef = useRef(null);
  const location = useLocation();

  // hamburger menu
  const [burgerClass, setBurgerClass] = useState("burger-bar")
  const [menuClass, setmenuClass] = useState("primary-navigation")
  const [isMenuClicked, setIsMenuClicked] = useState(false)

  const updateMenu = () => {
    if(!isMenuClicked){
      setBurgerClass("burger-bar clicked")
      setmenuClass("primary-navigation clicked")
      document.querySelector("body").classList.add("frozen");
    }
    else{
      setBurgerClass("burger-bar")
      setmenuClass("primary-navigation")
      document.querySelector("body").classList.remove("frozen");
    }
    setIsMenuClicked(!isMenuClicked)
  }


  // zavření menu po kliknutí na odkaz
  const closeMenu = useCallback(() => {
    if (isMenuClicked) {
      setBurgerClass("burger-bar");
      setmenuClass("primary-navigation");
      document.querySelector("body").classList.remove("frozen");
      setIsMenuClicked(false);
    }
  }, [isMenuClicked]);


  // když se klikne mimo menu, tak se zavře
  useEffect(() => {
    const handleClickOutsideNav = (event) => {
      if(!navRef.current.contains(event.target)){
        closeMenu();
      }
    };

    document.addEventListener("click", handleClickOutsideNav);

    return () => document.addEventListener("click", handleClickOutsideNav);
  }, [closeMenu]);


  // změna stylování hlavičky po scrollnutí
  useEffect(() => {
    const changeHeaderDesign = () => {
      const header = document.querySelector("header");
      if(window.scrollY > 0){ 
        header.classList.add("scrolled");
      }
      else{
        header.classList.remove("scrolled");
      }
    }
    
    window.addEventListener('scroll', changeHeaderDesign);

    return () => window.removeEventListener('scroll', changeHeaderDesign);
  }, []);

  // když někdo klikne na link, tak se stránka objeví na začátku
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 500);
  }, [location.pathname]);

  return(
    <header className="header" ref={navRef}>
      <Link to="/" onClick={closeMenu} className="nav-logo"><ReactSVG src={logo} /></Link>
      <nav className={menuClass}>
        <ul className="nav-ul">
          <CustomHashLink smooth to="/#uvod" onClick={closeMenu}>Úvod</CustomHashLink>
          <CustomHashLink smooth to="/#moje-sluzby" onClick={closeMenu}>Moje služby</CustomHashLink>
          <CustomLink to="/o-mne" onClick={closeMenu}>O mně</CustomLink>
          <CustomLink to="/kontakt" onClick={closeMenu}>Kontakt</CustomLink>
          <HashLink smooth to="/#moje-sluzby" className="button-fill-dark min-version" onClick={closeMenu}>Chci coaching</HashLink>  
        </ul>
      </nav> 
      <div className="nav-actions">
        <Link to="/kontakt" className="button-fill-dark min-version" onClick={closeMenu}>Chci coaching</Link>
        <div onClick={updateMenu} className={burgerClass}>
          <div className="top-line"></div>
          <div className="bottom-line"></div>
        </div>
      </div> 
    </header>
  );  
}

function CustomLink({ to, children, ...props }){
  const resolvedPath = useResolvedPath(to);
  const location = useLocation();
  const isActive = location.pathname === resolvedPath.pathname || location.pathname.startsWith(`${resolvedPath.pathname}/`);

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

function CustomHashLink({ to, children, ...props }){

  const location = useLocation();
  const [isActive, setIsactive] = useState("");

  useEffect(() => {
    if(location.pathname === '/'){
      setIsactive("/#uvod"); 
      const checkVisibleSection = () =>{
        const section = document.getElementById("moje-sluzby");
        if(section){
          const sectionTop = section.offsetTop;   
          if(window.pageYOffset >= sectionTop - 500 && window.pageYOffset <= sectionTop + 500){
            setIsactive("/#moje-sluzby");
          }
          else{
            setIsactive("/#uvod");
          }
        }
      }

      window.addEventListener('scroll', checkVisibleSection);

      return () => window.removeEventListener('scroll', checkVisibleSection);
    }
    else if(location.pathname === '/osobni-coaching' || location.pathname === '/online-coaching'){
      setIsactive("/#moje-sluzby");
    }
    else{
      setIsactive("");  
    }
  }, [location.pathname]);

  return (
    <li className={isActive === to ? "active" : ""}>
      <HashLink to={to} {...props}>
        {children}
      </HashLink>
    </li>
  );
}