import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

// Components
import SEO from '../components/SEO';
import Transition from '../components/Transition';

const Message = () => {

  const location = useLocation();
  const [content, setContent] = useState([
    {
      nadpis: "",
      text: "",
      btn: "",
      btnTo: ""
    }
  ]);

  useEffect(() => {
    if (location.pathname === '/kontakt/odeslano') {
      setContent([
        {
          title: "Odesláno",
          titleUrl: "odeslano",
          nadpis: "Zpráva odeslána!",
          text: "Budu Vás co nejdříve kontaktovat.",
          btn: "Zpět na domovskou stránku",
          btnTo: "/"
        }
      ]);
    } else if (location.pathname === '/kontakt/neodeslano') {
      setContent([
        {
          title: "Neodesláno",
          titleUrl: "neodeslano",
          nadpis: "Chyba při odesílání!",
          text: "Nepodařilo se odeslat zprávu. Můžete mě kontaktovat alternativně kliknutím na tlačítko níže.",
          btn: "Kontaktovat alternativně",
          btnTo: "mailto:doms.coaching@icloud.com"
        }
      ]);
    }
  }, [location.pathname]);

  // Funkce pro přidání třídy "error-page" na element s ID #root
  const addErrorClass = () => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.classList.add('error-page');
    }
  };
  
  // Funkce pro odebrání třídy "error-page" z elementu s ID #root
  const removeErrorClass = () => {
    const rootElement = document.getElementById('root');
    if (rootElement) {
      rootElement.classList.remove('error-page');
    }
  };
  
  // useEffect se spustí, když se komponenta načte (přistoupí na stránku)
  useEffect(() => {
    addErrorClass();
  
    // useEffect s návratovou funkcí se spustí při odstranění komponenty (odejde ze stránky)
    return () => {
      removeErrorClass();
    };
  }, []);

  return(
    <>
      <SEO title={`${content[0].title} • Dominik Šrámek`} pageURL={`domscoaching.cz/kontakt/${content[0].titleUrl}`} />
      <section>
        <h1>{content[0].nadpis}</h1>
        <p style={{ marginBottom: "1em" }}>{content[0].text}</p>
        <Link to={content[0].btnTo} className="button-fill-dark" style={{ fontSize: "1em" }}>{content[0].btn}</Link>
      </section>
    </>
  );
}

export default Transition(Message);