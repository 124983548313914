import { useState, useEffect, useMemo } from 'react';
import { HashLink } from "react-router-hash-link";
import { motion, useAnimationControls  } from "framer-motion";
import { ReactSVG } from 'react-svg';
import { Helmet } from 'react-helmet-async';

// Components
import SEO from '../components/SEO';
import Transition from '../components/Transition';
import Form from "../components/Form";
// import Reference from "../components/Reference";

// Images
import IntroductionFotoDesktop from "../assets/images/online-coaching/online-coaching-desktop.webp";
import IntroductionFotoTablet from "../assets/images/online-coaching/online-coaching-tablet.webp";

import ServicesVyzivoveATreninkovePlanyDesktop from "../assets/images/vyzivove-a-treninkove-plany/vyzivove-a-treninkove-plany-desktop.webp";
import ServicesVyzivoveATreninkovePlanyTablet from "../assets/images/vyzivove-a-treninkove-plany/vyzivove-a-treninkove-plany-tablet.webp";

import ServicesPoradenstviAKonzultaceDesktop from "../assets/images/poradenstvi-a-konzultace/poradenstvi-a-konzultace-desktop.webp";
import ServicesPoradenstviAKonzultaceTablet from "../assets/images/poradenstvi-a-konzultace/poradenstvi-a-konzultace-tablet.webp";

// Icons
import NoteIcon from "../assets/images/icons/note.svg";
import MicrophoneIcon from "../assets/images/icons/microphone.svg";


const OnlineCoaching = () => {
  
  const tabHeaders = useMemo(() => [
    ["Výživové a tréninkové plány", NoteIcon],
    ["Poradenství/konzultace", MicrophoneIcon]
  ], []);

  const servicesText = useMemo(() => [
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Duis condimentum augue id magna semper rutrum. Vivamus luctus egestas leo. Nullam rhoncus aliquam metus. Nam quis nulla. Etiam egestas wisi a erat. Nulla pulvinar eleifend sem. Nam libero tempore, cum soluta",
    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Duis condimentum augue id magna semper rutrum. Vivamus luctus egestas leo. Nullam rhoncus aliquam metus. Nam quis nulla. Etiam egestas wisi a erat. Nulla pulvinar eleifend sem. Nam libero tempore, cum soluta"
  ], []);

  const servicesFoto = useMemo(() => [
    [ServicesVyzivoveATreninkovePlanyDesktop, ServicesVyzivoveATreninkovePlanyTablet],
    [ServicesPoradenstviAKonzultaceDesktop, ServicesPoradenstviAKonzultaceTablet]
  ], []);

  const highlightWidth = [
    "51",
    "44"
  ];

  const highlightPosition = [
    "1",
    "54.9"
  ];

  const imageUrlsToPreload = [
    ServicesVyzivoveATreninkovePlanyDesktop,
    ServicesVyzivoveATreninkovePlanyTablet,
    ServicesPoradenstviAKonzultaceDesktop,
    ServicesPoradenstviAKonzultaceTablet 
  ];
  
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(activeIndex);
  const [isAnimating, setIsAnimating] = useState(false);
  const [headerText, setHeaderText] = useState(tabHeaders[activeIndex][0]);
  const [paragraphText, setParagraphText] = useState(servicesText[activeIndex]);
  const [subserviceFoto, setSubserviceFoto] = useState(servicesFoto[activeIndex]);
  const [subserviceIcon, setSubserviceIcon] = useState(tabHeaders[activeIndex][1]);

  const textControls = useAnimationControls();
  const fotoControls = useAnimationControls();
  const iconControls = useAnimationControls();

  useEffect(() => {
    const animation = async () => {
      setIsAnimating(true);
      
      // animace ikon
      await iconControls.start({
        scale: 0,
        transition: { duration: .1 },
      });
      
      iconControls.set({ scale: 0 });
      setSubserviceIcon(tabHeaders[activeIndex][1]);

      await iconControls.start({
        scale: 1,
        transition: { duration: .1 },
      });


      // animace textu
      await textControls.start(i => ({
        x: -100,
        opacity: 0,
        transition: { delay: i * 0.1, duration: .2 },
      }));

      textControls.set({ x: 100, opacity: 0 });
      setHeaderText(tabHeaders[activeIndex][0]);
      setParagraphText(servicesText[activeIndex]);

      await textControls.start(i => ({
        x: 0,
        opacity: 1,
        transition: { delay: i * 0.1, type: 'spring', stiffness: 50, velocity: 1 },
      }));


      // animace fotografií
      await fotoControls.start({
        scale: 0,
        transition: { duration: .4 },
      });
      
      setSubserviceFoto(servicesFoto[activeIndex]);
      fotoControls.set({ scale: 0 });
      
      await fotoControls.start({
        scale: 1,
        transition: { duration: .4 },
      });
      
      setPreviousIndex(activeIndex);
      setIsAnimating(false);
    };

    if (activeIndex !== previousIndex && !isAnimating) {
      animation();
    }

  }, [activeIndex, previousIndex, isAnimating, textControls, fotoControls, servicesText, tabHeaders, servicesFoto, iconControls]);


  return (
    <>
      <SEO title="Online coaching • Dominik Šrámek" pageURL="domscoaching.cz/online-coaching" />
      <Helmet>
        {imageUrlsToPreload.map((imageUrl, index) => (
          <link key={index} rel="preload" as="image" href={imageUrl} />
        ))}  
      </Helmet>
      <section className="choaching-introduction first-section">
        <h1>Online coaching</h1>
        <div className="choaching-introduction-content">
          <p>Při online coachingu Vám vytvořím tréninkový plán tak, aby vše vyhovovalo Vám i Vašemu tělu. Společně dosáhneme Vašich cílů, ať se jedná o redukci tělesného tuku nebo budování svalové hmoty.</p>
          <HashLink smooth to="/online-coaching#podsluzby" className="button-fill-dark">Možnosti coachingu</HashLink>
        </div>
        <div className="choaching-introduction-image">
          <picture>
            <source media="(min-width:992px)" srcSet={IntroductionFotoDesktop} width="1920" height="1280" />
            <img src={IntroductionFotoTablet} alt="Online trenér" loading="lazy" width="768" height="512" />
          </picture>  
        </div>
      </section>
      <section id="podsluzby">
        <h2>Možnosti coachingu</h2>
        <div>
          <div className="subservices-container">
            <div className="subservices-menu">
              {tabHeaders.map((tab, index) => (
                <button onClick={() => setActiveIndex(index)} key={index} className={`subservices-button ${activeIndex === index ? "active" : ""}`}><ReactSVG src={tab[1]} />{tab[0]}</button>
              ))}
              <div className="highlight-subservices-button" style={{ left: `${highlightPosition[activeIndex]}%`, width: `${highlightWidth[activeIndex]}%` }}></div>
            </div>
            <div className="subservices-content">
              <div className="subservices-content-inner center-layout">
                <div className="subservices-content-tab">
                  <div className="subservice-icon-container">
                    <motion.div className="subservice-icon" custom={0} animate={iconControls} initial={{ scale: 1 }}>
                      <ReactSVG src={subserviceIcon} />
                    </motion.div>
                  </div>
                  <motion.h3 custom={0} animate={textControls} initial={{ x: 0, opacity: 1 }}>{headerText}</motion.h3>
                  <motion.p custom={1} animate={textControls} initial={{ x: 0, opacity: 1 }}>{paragraphText}</motion.p>
                  <motion.div custom={2} animate={textControls} initial={{ x: 0, opacity: 1 }}>
                    <HashLink smooth to="/online-coaching#kontakt" className="button-fill-dark">Chci si domluvit schůzku</HashLink>
                  </motion.div>
                </div>
                <motion.div className="subservices-image-tab" animate={fotoControls} initial={{ scale: 1 }}>
                  <picture>
                    <source media="(min-width:992px)" srcSet={subserviceFoto[0]} width="1920" height="1280" />
                    <img src={subserviceFoto[1]} alt="Online trenér" loading="lazy" width="768" height="512" />
                  </picture>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="o-mne" className="services-about">
        <h2>O mně</h2>
        <p>Jmenuji se Dominik a jsem fitness trenér. Jako trenér působím v Premier fitness v Plzni. Mým cílem je plnění cílů a snů mých klientů, kteří si jdou bez ostychu za tím svým, ať už jde o redukci tělesného tuku nebo budování svalové hmoty. Po čas naší spolupráce nebudu jen Vaším trenérem, ale Vaším mentorem! Naučím Vás vážit si sám sebe! Hlavně nezapomínejte, že začátky bývají těžké, ale výsledek stojí za to!</p>
        <HashLink smooth to="/online-coaching#reference" className="button-fill-dark">Co říkají moji klienti</HashLink>
      </section>
      {/*<Reference />*/}
      <section style={{ display: "grid", placeItems: "center" }}>
        <Form id="kontakt" heading="test" />
      </section>
    </>
  );
};

export default Transition(OnlineCoaching);
