import { Link } from "react-router-dom";

// Components
import SEO from '../components/SEO';
import Transition from '../components/Transition';

// Images
import AboutFotoDesktop from "../assets/images/o-mne/o-mne-desktop.webp";
import AboutFotoTablet from "../assets/images/o-mne/o-mne-tablet.webp";

const About = () => {
  return(
    <>
      <SEO title="O mně • Dominik Šrámek" pageURL="https://domscoaching.cz/o-mne" />
      <section className="about-introduction first-section">
        <h1>Osobní trenér pro Váš hektický život</h1>
      </section>
      <section className="about-story">
        <div className="flex-col">
          <p>Jmenuji se Dominik a jsem fitness trenér. Jako trenér působím v Premier fitness v Plzni. Mým cílem je plnění cílů a snů mých klientů, kteří si jdou bez ostychu za tím svým, ať už jde o redukci tělesného tuku nebo budování svalové hmoty. Po čas naší spolupráce nebudu jen tvým trenérem, ale tvým mentorem! Naučím tě vážit si sám sebe! Hlavně nezapomínej, že začátky bývají těžké, ale výsledek stojí za to!</p>
        </div>
        <div className="flex-col">
          <picture>
            <source media="(min-width:992px)" srcSet={AboutFotoDesktop} width="1920" height="2880" />
            <img src={AboutFotoTablet} alt="Dominik Šrámek" loading="lazy" width="768" height="1152" />
          </picture>
        </div>
      </section>
      <section className="center-layout flex-start">
        <div>
          <h3>Flexibilní podpora pro Vaše fyzické a psychické zdraví</h3>
          <p>Dokážu se přizpůsobit Vaší finanční situaci i časovému harmonogramu. Zajímají mě Vaše koníčky a snažím se Vám pomoci nejen s fyzickými, ale i s psychickými problémy. Zakládám si na komunikaci i v případě, že byste náhodou nebyli s něčím spokojeni nebo chtěli něco změnit.</p>
        </div>
        <div>
          <h3>Profesionální trenér s důrazem na komfort a smyslplný trénink</h3>
          <p>Chápu, že čeština může být náročným jazykem, zejména pokud nejste rodilý mluvčí. Bezproblémově přecházím na jiný jazyk, abych zajistil Vaše pohodlí během naší spolupráce. Díky mému studiu i jiným školením se stále rozvíjím v nových znalostech o lidském těle. A pokud jste měli nějaké větší zranění, rád ho s Vámi proberu a zajistím pro Vás bezpečný a zároveň účinný trénink.</p>
        </div>  
      </section>
      <section className="about-cta">
        <div className="about-cta-content">
          <h3>Jste připraveni na splnění si svých fitness cílů?</h3>
          <p>Klikněte na tlačítko níže a vyberte si z mých služeb, které Vám pomohou dosáhnout vašich cílů a transformovat vaše tělo a život k lepšímu. </p>
          <Link to="/kontakt" className="button-fill-dark">Kontaktovat</Link>
        </div>
      </section>
    </>
  );
}

export default Transition(About);