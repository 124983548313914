import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { ReactSVG } from 'react-svg';

// Images
import logo from '../assets/images/logo/logo-mini-white.svg';

const Footer = () => {
  
  let date =  new Date().getFullYear();
  
  return (
    <footer>
      <div className="top-row-footer">
        <Link to="/" className="footer-logo"><ReactSVG src={logo} /></Link>
        <div className="footer-links-container">
          <div className="footer-links-wrapper">
            <h5>Menu</h5>
            <ul>
              <li><HashLink smooth to="/#uvod">Úvod</HashLink></li>
              <li><HashLink smooth to="/#moje-sluzby">Moje služby</HashLink></li>
              <li><Link to="/o-mne">O mně</Link></li>
              <li><Link to="/kontakt">Kontakt</Link></li>
            </ul>
          </div>
          <div className="footer-links-wrapper">
            <h5>Kontakt</h5>
            <ul>
              <li><Link to="tel:+420722233967"><u>+420 722 233 967</u></Link></li>
              <li><Link to="mailto:doms.coaching@icloud.com"><u>doms.coaching@icloud.com</u></Link></li>
            </ul>
          </div>
          <div className="footer-links-wrapper">
            <h5>Kde pracuji</h5>
            <p>Premier Fitness - Plzeň Plaza, Radčická 2</p>
          </div>
        </div>
      </div>
      <div className="bottom-row-footer">
        <p>Jsem fitness trenér, který Vám pomůže s Vašimi fitness cíli.</p>
        <div>
        <p>Copyright © {date} <Link to="/"><u>Dominik Šrámek</u></Link>. All Rights Reserved.</p>
        <p className="signature">Code by <Link to="https://dominikplecity.cz" target="_blank" rel="noopener noreferrer"><u>Dominik Plecitý</u></Link></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;