import { useState } from "react";
import { ReactSVG } from "react-svg";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

// Icons
import ExclamationMark from "../assets/images/icons/circle-exclamation-solid.svg";
import LoadingIcon from "../assets/images/icons/loading.svg";

const Form = ({ heading, ...props }) => {

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emptyName, setEmptyName] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      setEmptyEmail(true);
    }
    if (!name) {
      setEmptyName(true);
    }
    if (!message) {
      setEmptyMessage(true);
    }
    if (email && name && message) {
      setEmptyName(false);
      setEmptyEmail(false);
      setEmptyMessage(false);

      setLoading(true);

      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}sendemail`,
          { email, name, message },
          { headers: { "access-key": process.env.REACT_APP_ACCESS_KEY } }
        )
        .then((res) => {
          setLoading(false);
          navigate("/kontakt/odeslano");
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
          navigate("/kontakt/neodeslano");
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} {...props}>
        {/*<h3>{heading}</h3>*/}
        {/*<p>Vyplňte formulář a začněte svou cestu ke zdravějšímu a šťastnějšímu životu.</p>*/}
        <div className="inputs-constainer">
          <div className="input-row">
            <div className="input-container">
              <label htmlFor="vase-jmeno">Vaše jméno</label>
              <input
                type="text"
                id="vase-jmeno"
                name="vase-jmeno"
                placeholder=" "
                onChange={(e) => setName(e.target.value)}
              />
              <div className="form-message">
                {emptyName ? <ReactSVG src={ExclamationMark} /> : null}
                {emptyName ? "Vyplňte Vaše jméno" : null}
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="vas-email">Váš e-mail</label>
              <input
                type="email"
                id="vas-email"
                name="vas-email"
                placeholder=" "
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="form-message">
                {emptyEmail ? <ReactSVG src={ExclamationMark} /> : null}
                {emptyEmail ? "Vyplňte Váš e-mail" : null}
              </div>
            </div>
          </div>
          <div className="input-row">
            <div className="input-container">
              <label htmlFor="vase-zprava">Vaše zpráva</label>
              <textarea
                id="vase-zprava"
                name="vase-zprava"
                placeholder=" "
                onChange={(e) => setMessage(e.target.value)}
                rows="5"
              ></textarea>
              <div className="form-message">
                {emptyMessage ? <ReactSVG src={ExclamationMark} /> : null}
                {emptyMessage ? "Napište Váš dotaz" : null}
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="button-fill-light">
          {loading ? <ReactSVG src={LoadingIcon} /> : "Odeslat zprávu"}
        </button>
      </form>
    </>
  );
};

export default Form;
