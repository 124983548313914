import { Helmet } from 'react-helmet-async';

export default function SEO({ title, pageURL }){

  return(
    <Helmet>
      <link rel="canonical" href={pageURL}></link>
      <title>{title}</title>
    </Helmet>      
  );
}