import { Link } from "react-router-dom";
import { ReactSVG } from 'react-svg';

// Components
import SEO from '../components/SEO';
import Transition from '../components/Transition';
import Form from "../components/Form";

// Icons
import Chats from "../assets/images/icons/chats.svg";
import Phone from "../assets/images/icons/phone.svg";
import MapPin from "../assets/images/icons/map-pin.svg";
import Instagram from "../assets/images/icons/instagram.svg";
import Facebook from "../assets/images/icons/facebook.svg";

const Contact =() => {
  return(
    <>
      <SEO title="Kontakt • Dominik Šrámek" pageURL="https://domscoaching.cz/kontakt" />
      <section className="contact-container first-section">
        <h1>Kontakt</h1>
        <div className="contact-section center-layout">
          <div className="contact-links">
            <div className="contact-link-wrapper center-layout">
              <div className="contact-link-icon"><ReactSVG src={Chats} /></div>
              <div className="contact-link">
                <h5>Napište mi</h5>
                <Link to="mailto:doms.coaching@icloud.com"><u>doms.coaching@icloud.com</u></Link>
              </div>
            </div>
            <div className="contact-link-wrapper center-layout">
              <div className="contact-link-icon"><ReactSVG src={Phone} /></div>
              <div className="contact-link">
                <h5>Zavolejte</h5>
                <Link to="tel:+420722233967"><u>+420 722 233 967</u></Link>
              </div>
            </div>
            <div className="contact-link-wrapper center-layout">
              <div className="contact-link-icon"><ReactSVG src={MapPin} /></div>
              <div className="contact-link">
                <h5>Navštivte mě</h5>
                <p>Premier Fitness - Plzeň Plaza, Radčická 2</p>
              </div>
            </div>
            <div className="contact-link-wrapper center-layout">
              <Link to="https://www.instagram.com/domm__sramek__/" className="contact-link-icon socials" target="_blank" rel="noopener noreferrer"><ReactSVG src={Instagram} /></Link>
              <Link to="https://www.facebook.com/dominik.sramek.9279" className="contact-link-icon socials" target="_blank" rel="noopener noreferrer"><ReactSVG src={Facebook} /></Link>
            </div>
          </div>
          <Form heading="První krok k vašemu zdravějšímu a aktivnějšímu já!" />
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1570.9419216382335!2d13.368996855077171!3d49.749293594915954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470af1f9e47d6eed%3A0x1a93525b7f58b08e!2sPlze%C5%88%20Plaza!5e1!3m2!1scs!2scz!4v1688383864428!5m2!1scs!2scz" width="600" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="map" title="map-office"></iframe>
      </section>
    </>
  );
}

export default Transition(Contact);