import { Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { AnimatePresence } from "framer-motion";

// Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PersonalCoaching from "./pages/PersonalCoaching";
import OnlineCoaching from "./pages/OnlineCoaching";
import NotFound from "./pages/NotFound";
import Message from "./pages/Message";

// Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// Styles
import "./assets/styles/layout/layout.css";


function App() {
  const location = useLocation();
  return (
    <>
      <HelmetProvider>
        <Navbar />
        <AnimatePresence mode='wait' initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route index element={<Home />} />
            <Route path="/osobni-coaching" element={<PersonalCoaching />} />
            <Route path="/online-coaching" element={<OnlineCoaching />} />  
            <Route path="/o-mne" element={<About />} />
            <Route path="/kontakt" element={<Contact />} />
            <Route path="/kontakt/odeslano" element={<Message />} />
            <Route path="/kontakt/neodeslano" element={<Message />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AnimatePresence>
        <Footer />
      </HelmetProvider>
    </>
  );
}

export default App;
